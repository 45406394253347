// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered'
import {
  getFullFeeTypes,
  getFeeTypeMetaTemplate,
  getFeetypeTemplateById,
  getActiveFeeTypes,
  getLimitBillingPartyList,
  sortFeeTypebyDragging,
  getFeeTypeById
} from './custom-feetype-action'

import { getFeeTypeByIds } from './fetchUtil'

export default [
  {
    id: '@custom-feetype',
    reducer: () => import('./custom-feetype-reducer'),
    path: '/custom-feetype',
    ref: '/',
    onload: () => import('./custom-feetype-view'),
    'get:full:feetypes': (data, done) => api.dispatch(getFullFeeTypes(data, done)),
    'get:feetype:meta:template': (type) => api.dispatch(getFeeTypeMetaTemplate(type)),
    'get:limit:bill:party:list': () => api.dispatch(getLimitBillingPartyList()),

    getFeetypeTemplateById: (params) => api.dispatch(getFeetypeTemplateById(params)),
    getActiveFeeTypes: (params) => api.dispatch(getActiveFeeTypes(params)),
    getLimitBillingPartyList: (params) => api.dispatch(getLimitBillingPartyList(params)),
    getFeeTypeById: (params) => api.dispatch(getFeeTypeById(params)),
    'sort:feeType:byDragging': (params) => api.dispatch(sortFeeTypebyDragging(params)),
    'get:feeType:by:ids': (params) => getFeeTypeByIds(params)
  },

  {
    point: '@@components',
    namespace: '@custom-feetype',
    onload: () => [{ key: 'FeetypeForm', component: () => import('./custom-feetype-form') }]
  },
  {
    point: '@@layers',
    prefix: '@custom-feetype',
    onload: () => import('./layer')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'custom-feetype',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 6,
        //divider : true,
        label: '费用类型',
        href: '/custom-feetype',
        icon: 'enterprise-custom-conrd'
      }
    ]
  }
]
