export const ID = '@custom-feetype'

export default {
  ID,
  ID_COMMON: '@common',
  GET_FEETYPE_LIST: `${ID}/GET_FEETYPE_LIST`,
  GET_ACTIVE_FEETYPE_LIST: `${ID}/GET_ACTIVE_FEETYPE_LIST`,
  GET_FULL_FEETYPE_LIST: `${ID}/GET_FULL_FEETYPE_LIST`,
  GET_FEETYPE_BY_ID: `${ID}/GET_FEETYPE_BY_ID`,
  SAVE_FEETYPE: `${ID}/SAVE_FEETYPE`,
  DEL_FEETYPE: `${ID}/DEL_FEETYPE`,
  DISABLE_FEETYPE: `${ID}/DISABLE_FEETYPE`,
  RESTORE_FEETYPE: `${ID}/RESTORE_FEETYPE`,
  GET_FEETYPE_TEMPLATE: `${ID}/GET_FEETYPE_TEMPLATE`,
  MOVE_FEETYPE: `${ID}/MOVE_FEETYPE`,
  CREACT_FEETYPE: `${ID}/CREACT_FEETYPE`,
  GET_FEETYPE_META_TEMPLATE: `${ID}/GET_FEETYPE_META_TEMPLATE`,
  SORT_FEETYPE_BY_DRAGGING: `${ID}/SORT_FEETYPE_BY_DRAGGING`,
  BLOCK_BATCH_FEETYPE: `${ID}/BLOCK_BATCH_FEETYPE`,
  SYNC_BATCH_FEETYPE_FIELD: `${ID}/SYNC_BATCH_FEETYPE_FIELD`,
  LOAD_BATCH_FEETYPE_FIELD: `${ID}/LOAD_BATCH_FEETYPE_FIELD`,
  GET_LIMIT_BILLING_PARTY: `${ID}/GET_LIMIT_BILLING_PARTY`
}
