/************************************************
 * Created By nanyuantingfeng On 6/7/16 11:48.
 ************************************************/
import key from './key'
import { Resource } from '@ekuaibao/fetch'
const feeTypeRes = new Resource('/api/v1/form/feeTypes')
const specifications2 = new Resource('/api/form/v2/specifications')
const limitBillingParty = new Resource('/api/v2/invoice/unify/corporation')

export function getActiveFeeTypes(data, done) {
  let params = {
    ...data
  }
  return {
    type: key.GET_ACTIVE_FEETYPE_LIST,
    payload: feeTypeRes.GET('/tree/active', params),
    done
  }
}

export function getFeetypeTemplateById(id, done) {
  return {
    type: key.GET_FEETYPE_TEMPLATE,
    payload: specifications2.GET('/feeType/[id]', { id }),
    done
  }
}

export function getFullFeeTypes(data, done) {
  let params = {
    ...data,
    join: 'id,feetypeTemplate,/form/v2/specifications/feeType',
    join$2: 'children.id,feetypeTemplate,/form/v2/specifications/feeType'
  }
  return {
    type: key.GET_FULL_FEETYPE_LIST,
    payload: feeTypeRes.GET('/tree', params),
    done
  }
}

export function getFeeTypeById(data, done) {
  return {
    type: key.GET_FEETYPE_BY_ID,
    payload: feeTypeRes.GET('/$id', data),
    done
  }
}

export function saveFeeType(data, done) {
  let params = { ...data }
  //params.components = data.components.map(o => ({...o, id: undefined}))
  return {
    type: key.SAVE_FEETYPE,
    payload: feeTypeRes.PUT('/$id', params),
    done
  }
}

export function createFeeType(data, done) {
  return {
    type: key.CREACT_FEETYPE,
    payload: feeTypeRes.POST(data),
    done
  }
}

export function delFeeType(data, done) {
  return {
    type: key.DEL_FEETYPE,
    payload: feeTypeRes.DELETE(data),
    done
  }
}

export function disableFeeType(data, done) {
  return {
    type: key.DISABLE_FEETYPE,
    payload: feeTypeRes.PUT('/$id/disable', data),
    done
  }
}

export function restoreFeeType(data, recursive, done) {
  return {
    type: key.RESTORE_FEETYPE,
    payload: feeTypeRes.PUT('/$id/restore', data, { recursive }),
    done
  }
}

export function moveFeeType(data, parentId, done) {
  return {
    type: key.MOVE_FEETYPE,
    payload: feeTypeRes.PUT('/[ids]/parent', data, parentId),
    done
  }
}

export function getFeeTypeMetaTemplate(type) {
  return {
    type: key.GET_FEETYPE_META_TEMPLATE,
    payload: specifications2.GET('/default/$type', { type }),
    metaType: type
  }
}

export function getLimitBillingPartyList() {
  return {
    type: key.GET_LIMIT_BILLING_PARTY,
    payload: limitBillingParty.GET('/list')
  }
}

export function blockBatchfeeType(data, done) {
  return {
    type: key.BLOCK_BATCH_FEETYPE,
    payload: feeTypeRes.PUT('/[ids]/disable', data),
    done
  }
}

export function sortFeeTypebyDragging(data, done) {
  return {
    type: key.SORT_FEETYPE_BY_DRAGGING,
    payload: feeTypeRes.POST('/modify/order', data),
    done
  }
}

export function loadBatchFeeTypeField(data, done) {
  return {
    type: key.LOAD_BATCH_FEETYPE_FIELD,
    payload: feeTypeRes.POST('/syncField/classify', data),
    done
  }
}

export function syncBatchFeeTypeField(data, done) {
  return {
    type: key.SYNC_BATCH_FEETYPE_FIELD,
    payload: feeTypeRes.POST('/batch/addField', data),
    done
  }
}
